/* -----------    Общие    ----------- */

.hidden {
  display: none;
}

.red {
  color: #EF6957;
}


/* -----------    Хедер    ----------- */


/* -----------    Главная (подписка)    ----------- */

.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent;
  font-weight: normal;
}

.ant-select-item:hover {
  color: #4985DF;
  background: #F5F7FB;
  font-weight: 600;
}

/*.ant-select-arrow {*/

/*  transform: rotate(0deg);*/
/*  transition: transform .25s;*/
/*}*/

/*.ant-select.ant-select-open .ant-select-arrow {*/
/*  transform: rotate(180deg);*/
/*}*/

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  margin-top: 0!important;
}

.ant-select-selection-search-input {
  margin-top: 1px;
}

.vacancy-auto-complete  .ant-select-selection-search-input {
  margin-top: -1px!important;
}

.vacancy-auto-complete-form {
  margin-top: 9px;
}

@media (min-width: 990px) {
  .vacancy-auto-complete  .ant-select-selection-search {
    padding-left: 19px;
    margin-top: -1px;
  }
}

@media (max-width: 990px) {
  .vacancy-auto-complete-form {
    margin-top: 0px;
  }

  .vacancy-auto-complete .ant-select-selection-search {
    padding-left: 1px;
    height: 37px!important;
  }
}

.ant-select-dropdown {
  /*transform: translateY(-8px);*/
  box-shadow: 0 8px 20px rgb(0 0 0 / 10%);
  /*position: inherit!important;*/
  /*top: 40px!important;*/
  border-radius: unset;
  /*border-top: 1px solid transparent;*/
  /*border-color: #323232;*/

  padding-top: 8px;
  padding-bottom: 14px;
}

.custom-email-select .ant-select-selection-search input {
  border: none!important;
  padding: 0!important;
}

.custom-email-select-option.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.custom-email-select-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ef735712;
}

.custom-dropdown-container {
  z-index: 1050;
  position: absolute;
}

.custom-dropdown-container .rc-virtual-list {
  display: flex;
}

.custom-dropdown-container .rc-virtual-list-holder {
  flex: 1;
}

.custom-dropdown-container .rc-virtual-list-scrollbar {
  position: relative!important;
  width: 16px!important;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
}

.rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
  display: block!important;
  margin-left: 15px;
}

.custom-dropdown-container .rc-virtual-list-scrollbar-thumb {
  position: relative!important;
  width: 4px!important;
  margin: 0 auto;
  border-radius: 16px!important;
  text-align: center!important;

  background: #C8D2E7!important;
}

@media (max-width: 990px) {
  .rc-virtual-list-holder {
    margin-right: 0px;
  }

  .ant-select-dropdown {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.ant-select {
  position: relative;
}

.ant-select-arrow {
  position: absolute;
  right: 25px;
  top: 36px;
  width: 6px;
  height: 6px;
  border-left: 1px solid #323232;
  border-top: 1px solid #323232;
  transform: rotate(
          -135deg
  );
  transition: transform .25s;
  z-index: 1;
}

.ant-select-suffix {
  display: none;
  /*position: absolute;*/
  /*right: 25px;*/
  /*top: 29px;*/
/*  width: 6px;
  height: 6px;
  border-left: 1px solid #323232;
  border-top: 1px solid #323232;
  transform: rotate(
          -135deg
  );
  transition: transform .25s;
  z-index: 1;*/
}

.ant-select.ant-select-open .ant-select-arrow {
  transform: rotate(
          45deg
  );
}

@media (max-width: 990px) {
  .ant-select-arrow {
    right: 13px;
    top: 21px;
    width: 8px;
    height: 8px;
  }
}

.ant-select-suffix svg {
  display: none;
}


/* -----------    Страница отправки email для инструкций по подписке    ----------- */



/* -----------    Страница списка вакансий    ----------- */

.vacancy-list__item-right p,
.vacancy-list__item-right__content,
.vacancy-list__item-right__ellipsis {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.8;
}

.vacancy-list__item-right p,
.vacancy-list__item-right__content {
  max-height: 96px;
  overflow: hidden;
}

@media (max-width: 1440px) {
  .vacancy-list__item-right p,
  .vacancy-list__item-right__content {
    font-size: 14px;
    line-height: 22px;

    max-height: 88px;
  }
}

@media (max-width: 990px) {
  .vacancy-list__item-right p,
  .vacancy-list__item-right__content {
    font-size: 12px;
    line-height: 20px;

    max-height: 80px;
  }
}

.vacancy-list__item-right__ellipsis {
  font-weight: normal;
}

.vacancy__banner {
  display: block;
  max-width: 656px;
  width: 100%;
  margin: 20px auto 80px auto; }
  @media (max-width: 990px) {
    .vacancy__banner {
      margin-bottom: 40px; }}
  @media (max-width: 575px) {
    .vacancy__banner {
      margin-bottom: 20px; }}

.vacancy__banner img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/* -----------    Страница пополнения средств (vs-refill)    ----------- */

.refill-amount-inputs {
  display: flex;
  align-items: center;
  max-width: 400px;
  margin-top: 30px }
  @media (max-width: 990px) {
  .refill-amount-inputs {
    margin-top: 10px; }}

.change-refill-amount-btn {
  padding: 0;
  width: 54px;
  font-size: 42px;
  font-weight: 100; }
  @media (max-width: 1440px) {
    .change-refill-amount-btn {
      width: 52px; }}
  @media (max-width: 990px) {
    .change-refill-amount-btn {
      width: 48px;
      font-size: 36px; }}

.decrease-refill-amount-btn {
  padding-bottom: 1%;
}

.input-block .refill-amount {
  box-sizing: border-box;
  width: 170px;
  text-align: center;
  color: #323232;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  transition: 0.3s;
  position: relative;
  z-index: 1;
  transition-property: border; }
  @media (max-width: 990px) {
    .input-block .refill-amount {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      padding-left: 12px;
      padding-right: 12px; }}
  @media (max-width: 575px) {
    .input-block .refill-amount {
      flex: 1; }}


/* -----------    Мод. форма отправки вакансии другу    ----------- */

.share-vacancy-modal .sm-modal-contant {
  width: auto;
  padding-top: 60px; }

.share-vacancy-modal .opt-modal__wp {
  text-align: center; }

.opt-modal__wp .opt-modal-error-title {
  /*color: #d78787;*/
}


.share-vacancy-modal .p2 {
  text-align: left; }

.share-vacancy-modal .opt-modal__bottom {
  justify-content: center; }

.share-vacancy-modal .btn {
  width: auto;
  padding-left: 10%;
  padding-right: 10%; }
  @media (max-width: 360px) {
    .share-vacancy-modal .btn {
      width: 100%; }}

.share-vacancy-modal .btn svg {
  margin-left: 14px;
  stroke: white;
  z-index: 1000; }
  .share-vacancy-modal .btn[disabled] svg {
    stroke: #8A8A89; }

.share-vacancy-modal .input-block input {
  max-width: none; }


/* -----------    Блок инвайтной ссылки    ----------- */
