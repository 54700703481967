@charset "UTF-8";

body {
  font-feature-settings: normal !important;
  -webkit-font-feature-settings: normal !important;
  font-variant: none !important;
}

.videoPlay {
  width: 80px;
  height: 80px;
  background-color: rgba(255,255,255,.06);
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  transition: .3s;
  transition-property: background
}

.videoPlay svg {
  margin-left: 5px
}

.videoPlay:hover {
  background-color: #009DFF
}

*,[data-whatintent=mouse] :focus,[data-whatintent=touch] :focus {
  outline: none
}

* {
  box-sizing: border-box
}

:after,:before {
  box-sizing: inherit
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #323232;
  overflow-x: hidden;
  cursor: default
}

@media (max-width: 1440px) {
  body {
    font-size:14px;
    line-height: 19px
  }
}

@media (max-width: 990px) {
  body {
    font-size:12px;
    line-height: 18px
  }
}

@media (max-width: 480px) {
  body {
    overflow-x:hidden;
    max-width: 100vw
  }
}

main {
  overflow-x: hidden
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
  padding: 0
}

ol,ul {
  margin: 0
}

ol.no-style,ul.no-style {
  padding: 0;
  list-style: none
}

button {
  cursor: pointer;
  font-family: Montserrat,sans-serif
}

button,img {
  border: none
}

a {
  text-decoration: none;
  color: inherit
}

[contenteditable=true]:empty:before {
  content: attr(data-placeholder);
  color: #131313;
  font-size: inherit;
  display: block
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

select::-ms-expand {
  display: none
}

input[type=number] {
  -moz-appearance: textfield
}

input::-webkit-inner-spin-button,input::-webkit-outer-spin-button {
  -webkit-appearance: none
}

b {
  font-weight: 700
}

blockquote {
  margin: 0;
  padding: 4px 19px 4px 22px;
  border-left: 2px solid #323232
}

blockquote,blockquote p {
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px
}

blockquote p {
  margin-bottom: 11px
}

blockquote p:last-of-type {
  margin-bottom: 0
}

@media (max-width: 1440px) {
  blockquote {
    padding-left:18px
  }

  blockquote p {
    font-size: 16px;
    line-height: 22px
  }
}

@media (max-width: 1140px) {
  blockquote {
    padding-right:0
  }
}

@media (max-width: 990px) {
  blockquote,blockquote p {
    font-size:14px;
    line-height: 20px
  }
}

@media (max-width: 760px) {
  blockquote {
    padding-top:1px;
    padding-left: 15px;
    padding-bottom: 0
  }

  blockquote,blockquote p {
    font-size: 12px;
    line-height: 16px
  }

  blockquote p {
    margin-bottom: 9px
  }
}

h1,h2,h3,h4,h5,h6 {
  color: #323232
}

h1,h2 {
  font-weight: 300
}

h3,h4,h5 {
  font-weight: 400
}

h6 {
  font-weight: 500
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 68px;
  color: #182A32
}

@media (max-width: 1440px) {
  h1 {
    font-size:42px;
    line-height: 48px
  }
}

@media (max-width: 990px) {
  h1 {
    font-style:normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px
  }
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px
}

@media (max-width: 1440px) {
  h2 {
    font-size:28px;
    line-height: 34px
  }
}

@media (max-width: 990px) {
  h2 {
    font-style:normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px
  }
}

h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px
}

@media (max-width: 1440px) {
  h3 {
    font-size:21px;
    line-height: 25px
  }
}

@media (max-width: 990px) {
  h3 {
    font-style:normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px
  }
}

h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px
}

@media (max-width: 990px) {
  h4 {
    font-style:normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px
  }
}

a,h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px
}

@media (max-width: 990px) {
  a,h5 {
    font-size:14px;
    line-height: 20px
  }
}

h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px
}

.container {
  max-width: 1500px;
  width: 100%;
  margin: auto;
  padding: 0 30px
}

@media (max-width: 1600px) {
  .container {
    padding:0 70px
  }
}

@media (max-width: 1140px) {
  .container {
    padding:0 20px
  }
}

.podz {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px
}

@media (max-width: 990px) {
  .podz {
    font-style:normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px
  }
}

.p1 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px
}

@media (max-width: 1440px) {
  .p1 {
    font-size:20px;
    line-height: 24px
  }
}

@media (max-width: 990px) {
  .p1 {
    font-style:normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px
  }
}

.p2 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px
}

@media (max-width: 1140px) {
  .p2 {
    font-style:normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px
  }

  h5 {
    font-size: 16px;
    line-height: 20px
  }
  .support-email {
    font-size: 16px;
  }
}

@media (max-width: 990px) {
  .p2 {
    font-style:normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px
  }
  .support-email {
    font-size: 12px;
  }
}

.p3 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px
}

@media (max-width: 990px) {
  .p3 {
    font-style:normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px
  }
}

.podp {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px
}

@media (max-width: 990px) {
  .podp {
    font-style:normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px
  }
}

.btn {
  font-family: Montserrat,sans-serif;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #EF6957;
  padding: 10px 20px 9px;
  min-width: 151px;
  height: 54px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #F9F9F9;
  transition: background .25s,color .25s;
  outline: none;
  cursor: pointer
}

.btn:hover {
  background: #FF6E5A;
  border-color: #FF6E5A;
}

.btn[disabled]:not(.profile-positions-btn),
.btn[disabled]:not(.profile-positions-btn):focus,
.btn[disabled]:not(.profile-positions-btn):hover {
  background: #F9F9F9;
  border-color: #F9F9F9;
  color: #8A8A89;
  cursor: not-allowed
}

.btn.profile-positions-btn[disabled] {
  /*color: #8A8A89;*/
  cursor: not-allowed
}

.btn.profile-positions-btn[disabled]:hover {
  background: #EF6957;
}

.btn:active {
  background: #FF6E5A;
  color: #fff
}

@media (max-width: 1440px) {
  .btn {
    height:52px
  }
}

@media (max-width: 990px) {
  .btn {
    font-size:14px;
    line-height: 16px;
    height: 48px
  }
}

.btn2 {
  font-family: Montserrat,sans-serif;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #ffffff;
  padding: 10px 40px 9px;
  height: 54px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #323232;
  transition: background .25s,color .25s,border .25s;
  border: 1px solid #323232;
  outline: none;
  cursor: pointer
}

.btn2:focus,
.btn2:hover {
  border-color: #EF6957;
  color: #EF6957
}

.btn2[disabled] {
  background: #ffffff;
  color: #8A8A89;
  border-color: #F9F9F9;
  cursor: not-allowed
}

.btn2:active {
  color: #EF6957;
  border-color: #EF6957
}

@media (max-width: 1440px) {
  .btn2 {
    height:52px
  }
}

@media (max-width: 990px) {
  .btn2 {
    font-size:14px;
    line-height: 16px;
    height: 48px
  }
}

.a1 {
  color: #8A8A89;
  transition: .25s;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent
}

.a1:hover {
  border-color: #EF6957;
  color: #323232
}

.a1[disabled] {
  color: #8A8A89;
  opacity: .5;
  border-color: transparent
}

.a2 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #4985DF;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
  display: inline;
  transition: .25s
}

.a2:hover {
  border-color: #4985DF
}

.link {
  display: block;
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 190%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF;
  opacity: .8;
  transition: .3s;
  transition-property: opacity
}

.link:hover {
  opacity: 1
}

.link:active {
  opacity: .8
}

.link[disabled] {
  opacity: .2
}

.link-arrow {
  position: relative;
  padding-right: 27px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px
}

.link-arrow span {
  width: 16px;
  height: 16px;
  overflow: hidden;
  position: absolute;
  top: 3px;
  left: calc(100% - 16px);
  display: inline-block;
  transition: .25s
}

.link-arrow span svg {
  position: absolute;
  right: 0;
  top: 0;
  transition: .25s
}

.link-arrow:hover span {
  width: 30px
}

.link-arrow:hover span svg {
  fill: #EF6957
}

@media (max-width: 575px) {
  .link-arrow div {
    display:none
  }

  .link-arrow span {
    top: -9px
  }
}

.input-block {
  display: block;
  position: relative;
  margin-top: 38px
}

.input-block:first-of-type {
  margin-top: 0
}

/*.input-block_check .input-block__title {*/
/*  display: -ms-flexbox;*/
/*  display: flex;*/
/*  -ms-flex-pack: justify;*/
/*  justify-content: space-between*/
/*}*/

/*.input-block_check .default-checkbox {*/
/*  margin-top: -7px*/
/*}*/

.input-block .p2 {
  margin-bottom: 5px
}

/*.input-block__title p {*/
/*  font-style: normal;*/
/*  font-weight: 700;*/
/*  font-size: 24px;*/
/*  line-height: 32px;*/
/*  color: #323232*/
/*}*/

.input-block input,.input-block textarea {
  border: 1px solid #8A8A89;
  color: #323232;
  font-family: Montserrat,sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  padding-left: 30px;
  padding-right: 15px;
  transition: .3s;
  position: relative;
  z-index: 1;
  transition-property: border;
  height: 64px;
  box-sizing: border-box;
  width: 100%;
  max-width: 580px;
  margin-top: 9px
}

.input-block input:focus::-webkit-input-placeholder,.input-block textarea:focus::-webkit-input-placeholder {
  color: transparent
}

.input-block input:focus:-moz-placeholder,.input-block input:focus::-moz-placeholder,.input-block textarea:focus:-moz-placeholder,.input-block textarea:focus::-moz-placeholder {
  color: transparent
}

.input-block input:focus:-ms-input-placeholder,.input-block textarea:focus:-ms-input-placeholder {
  color: transparent
}

.input-block textarea {
  padding-top: 20px
}

/*.input-block__subtitle {*/
/*  margin-top: 5px;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-size: 16px;*/
/*  line-height: 24px;*/
/*  color: #6A6E7C*/
/*}*/

.input-block .message {
  width: 100%;
  position: absolute;
  bottom: -18px;
  left: 0;
  transform: translateY(90%);
  padding: 18px 24px 16px;
  background-color: white;
  color: #131225;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  visibility: hidden;
  opacity: 0;
  transition: .3s linear;
  z-index: 1
}

.input-block .message:after {
  content: "";
  position: absolute;
  top: 0;
  left: 26px;
  transform: translateY(-50%) rotate(45deg);
  width: 12px;
  height: 12px;
  background-color: white
}

@media (max-width: 990px) {
  .input-block {
    margin-top:16px
  }

  .input-block .p2 {
    margin-bottom: 4px
  }

  .input-block input,.input-block textarea {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 0
  }

  .input-block textarea {
    padding-top: 12px
  }

  .input-block input {
    height: 40px
  }
}

@media (max-width: 575px) {
  .input-block {
    margin-bottom:15px;
    margin-top: 14px
  }

  .input-block:before {
    right: 13px;
    top: 16px;
    width: 8px;
    height: 8px
  }
}

/*@-moz-document url-prefix() {*/
/*  .sm-modal-body {*/
/*    background-color: rgba(0,0,0,.6)!important*/
/*  }*/
/*}*/


.simple-select__item {
  cursor: pointer;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #323232;
  margin-bottom: 0;
  height: 42px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  /*transform: translateY(-10px);*/
  opacity: 1;
  transition-property: opacity,transform,color,background-color;
  padding-left: 30px
}

.simple-select__item::before {
  content: url(img/main/ok.svg);
  position: absolute;
  right: 5px;
  top: 13px;
  opacity: 0;
  transition: .2s linear;
  transition-property: opacity
}

.simple-select__item:last-child {
  margin-bottom: 0
}

.simple-select__item.is-active {
  color: #232323
}

.simple-select__item.is-active::before {
  opacity: 1
}

@media (min-width: 1070px) {
  .simple-select__item:focus,.simple-select__item:hover {
    color:#4985DF;
    background: #F5F7FB;
    font-weight: 600
  }
}

@media (max-width: 990px) {
  .simple-select__item {
    height:36px;
    padding-right: 15px
  }

  .simple-select__item:before {
    right: 0
  }
}

@media (max-width: 990px) {
  .simple-select__item {
    font-size: 12px;
    line-height: 15px;
    padding-left: 12px
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden
}

.apple-fix {
  width: 100%;
  position: fixed
}

.no-scroll {
  overflow: hidden
}

.img-wrapper {
  display: inline-block
}

.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0
}


footer,header,main {
  transition: .25s linear;
  transition-property: filter
}


.support-email {
  font-size: 18px;
}


@media (max-width: 1140px) {
  .support-email {
    font-size: 16px;
  }
}

@media (max-width: 990px) {
  .support-email {
    font-size: 12px;
  }
}

/*# sourceMappingURL=styles.min.css.map */
