.vacancy blockquote {
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin-top: 47px;
    padding-left: 27px
}

.vacancy blockquote p {
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit
}


@media (max-width: 1440px) {
    .vacancy blockquote {
        margin-top: 35px
    }
}

@media (max-width: 990px) {
    .vacancy blockquote {
        font-size: 12px;
        line-height: 18px;
        margin-top: 28px;
        padding-left: 15px;
        padding-top: 2px;
        padding-bottom: 4px
    }
}


.vacancy__right {
    max-width: 418px;
    width: 29%;
    position: relative
}

.vacancy__right-img {
    height: 281px
}

.vacancy__right-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center
}

@media (min-width: 991px) {
    .vacancy__right-img+.vacancy__right-info {
        padding-top:32px
    }
}

.vacancy__right-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    position: relative;
    padding-bottom: 18px
}

.vacancy__right-title:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 2px;
    background: #EF6957
}

.vacancy__right-wp {
    background: #F5F7FB
}

.vacancy__right-info {
    padding: 39px 40px 46px
}

.vacancy__right .p2 {
    color: #7E8593;
    margin-top: 25px
}

.vacancy__right .btn2 {
    margin-top: 40px;
    width: 100%;
    font-size: 20px;
    line-height: 24px;
    height: 64px
}

@media (max-width: 1440px) {
    .vacancy__right .btn2 {
        font-size:18px;
        line-height: 22px;
        padding-left: 25px;
        padding-right: 25px;
        height: 56px
    }

    .vacancy__right-info,.vacancy__right .btn {
        padding-left: 15px;
        padding-right: 15px
    }

    .vacancy__right .btn {
        height: 58px
    }
}

@media (max-width: 1140px) {
    .vacancy__right {
        width: 100%;
        max-width: 460px;
        margin-left: auto;
        margin-right: auto
    }
}

@media (max-width: 575px) {
    .vacancy .vacancy__right-img {
        height: 205px
    }

    .vacancy .vacancy__right-info {
        padding: 24px 11px 28px
    }

    .vacancy .vacancy__right-title {
        font-size: 16px;
        line-height: 22px;
        padding-bottom: 10px
    }

    .vacancy .vacancy__right .p2 {
        margin-top: 20px;
        line-height: 18px
    }

    .vacancy .vacancy__right .btn2 {
        margin-top: 24px;
        height: 48px;
        font-size: 14px;
        line-height: 16px
    }
}


@media (min-width: 1441px) {
    .vacancy-list h1 {
        line-height:78px
    }
}

.vacancy-list .vacancy__right-title {
    padding-bottom: 14px
}

.vacancy-list .vacancy__right .p2 {
    margin-top: 32px
}

.vacancy-list .vacancy__right .btn {
    margin-top: 46px;
    height: 64px;
    font-size: 20px;
    line-height: 24px;
    width: 100%;
    max-width: 360px
}

@media (max-width: 1440px) {
    .vacancy-list .vacancy__right {
        display: block
    }

    .vacancy-list .vacancy__right .btn,.vacancy-list .vacancy__right .btn2 {
        font-size: 16px;
        line-height: 20px;
        height: 56px
    }
}

@media (max-width: 1140px) {
    .vacancy-list .vacancy__right {
        width: 29%
    }
}

@media (max-width: 990px) {
    .vacancy-list .vacancy__right {
        width: 100%;
        margin-top: 46px
    }

    .vacancy-list .vacancy__right .btn,.vacancy-list .vacancy__right .btn2 {
        font-size: 14px;
        line-height: 16px;
        height: 48px;
        margin-top: 24px
    }

    .vacancy-list .vacancy__right-title {
        font-size: 16px;
        line-height: 22px;
        padding-bottom: 11px
    }

    .vacancy-list .vacancy__right-info {
        padding: 23px 12px 28px
    }

    .vacancy-list .vacancy__right .p2 {
        margin-top: 20px;
        line-height: 18px
    }
}


/* vacancy-list__item */


.vacancy-list__item {
    padding-top: 48px;
    padding-bottom: 48px;
    border-bottom: 1px solid #E2E3E6;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.vacancy-list__item:first-of-type {
    border-top: 1px solid #E2E3E6
}

.vacancy-list__item-left {
    width: 62%;
    max-width: 610px
}

.vacancy-list__item-right {
    width: 35%;
    z-index: -1;
}

.vacancy-list__item-right p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    opacity: .8
}

.vacancy-list__item-title {
    display: block
}

.vacancy-list__item-title .p1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: inline;
    border-bottom: 1px solid transparent;
    transition: border .25s
}

.vacancy-list__item-title svg {
    margin-bottom: 2px;
    z-index: 1
}

.vacancy-list__item-title button {
    background: transparent;
    display: inline;
    padding: 0;
    position: relative
}

.vacancy-list__item-title button:hover svg {
    fill: #EF6957
}

.vacancy-list__item .p3 {
    margin-top: 20px
}

.vacancy-list__item-price {
    margin-top: 24px;
    color: #8A8A89
}

@media (min-width: 991px) {
    .vacancy-list__item:hover .vacancy-list__item-title .p1 {
        border-bottom:1px solid #EF6957
    }
}

.vacancy-list__item .ya-share2__container_size_m .ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__link_more {
    opacity: 0
}

.vacancy-list__item .ya-share2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1
}

@media (max-width: 1440px) {
    .vacancy-list__item-title .p1 {
        font-size: 20px;
        line-height: 24px
    }

    .vacancy-list__item-right p {
        font-size: 14px;
        line-height: 22px
    }
}

@media (max-width: 990px) {
    .vacancy-list__item-title .p1 {
        font-size: 16px;
        line-height: 22px
    }

    .vacancy-list__item-title svg {
        width: 12px;
        height: 12px;
        margin-bottom: 0;
        margin-left: 3px;
        margin-top: 2px
    }

    .vacancy-list__item-price {
        margin-top: 11px
    }

    .vacancy-list__item-right p {
        font-size: 12px;
        line-height: 20px
    }

    .vacancy-list__item-price {
        font-size: 14px;
        line-height: 22px
    }
}

@media (max-width: 575px) {
    .vacancy-list__item {
        display: block;
        padding-top: 20px;
        padding-bottom: 20px
    }

    .vacancy-list__item-left {
        width: 100%
    }

    .vacancy-list__item-right {
        width: 100%;
        margin-top: 20px
    }
}




