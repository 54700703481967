@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Light.eot');
	src: local('Montserrat Light'), local('/fonts/Montserrat-Light'),
		url('/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-Light.woff') format('woff'),
		url('/fonts/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-MediumItalic.eot');
	src: local('Montserrat Medium Italic'), local('/fonts/Montserrat-MediumItalic'),
		url('/fonts/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-MediumItalic.woff') format('woff'),
		url('/fonts/Montserrat-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Thin.eot');
	src: local('Montserrat Thin'), local('/fonts/Montserrat-Thin'),
		url('/fonts/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-Thin.woff') format('woff'),
		url('/fonts/Montserrat-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-ExtraLightItalic.eot');
	src: local('Montserrat ExtraLight Italic'), local('/fonts/Montserrat-ExtraLightItalic'),
		url('/fonts/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-ExtraLightItalic.woff') format('woff'),
		url('/fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-BoldItalic.eot');
	src: local('Montserrat Bold Italic'), local('/fonts/Montserrat-BoldItalic'),
		url('/fonts/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-BoldItalic.woff') format('woff'),
		url('/fonts/Montserrat-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-SemiBold.eot');
	src: local('Montserrat SemiBold'), local('/fonts/Montserrat-SemiBold'),
		url('/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-SemiBold.woff') format('woff'),
		url('/fonts/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-ExtraLight.eot');
	src: local('Montserrat ExtraLight'), local('/fonts/Montserrat-ExtraLight'),
		url('/fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-ExtraLight.woff') format('woff'),
		url('/fonts/Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-ExtraBoldItalic.eot');
	src: local('Montserrat ExtraBold Italic'), local('/fonts/Montserrat-ExtraBoldItalic'),
		url('/fonts/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-ExtraBoldItalic.woff') format('woff'),
		url('/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Italic.eot');
	src: local('Montserrat Italic'), local('/fonts/Montserrat-Italic'),
		url('/fonts/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-Italic.woff') format('woff'),
		url('/fonts/Montserrat-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Bold.eot');
	src: local('Montserrat Bold'), local('/fonts/Montserrat-Bold'),
		url('/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-Bold.woff') format('woff'),
		url('/fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-LightItalic.eot');
	src: local('Montserrat Light Italic'), local('/fonts/Montserrat-LightItalic'),
		url('/fonts/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-LightItalic.woff') format('woff'),
		url('/fonts/Montserrat-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-BlackItalic.eot');
	src: local('Montserrat Black Italic'), local('/fonts/Montserrat-BlackItalic'),
		url('/fonts/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-BlackItalic.woff') format('woff'),
		url('/fonts/Montserrat-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-SemiBoldItalic.eot');
	src: local('Montserrat SemiBold Italic'), local('/fonts/Montserrat-SemiBoldItalic'),
		url('/fonts/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-SemiBoldItalic.woff') format('woff'),
		url('/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Regular.eot');
	src: local('Montserrat Regular'), local('/fonts/Montserrat-Regular'),
		url('/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-Regular.woff') format('woff'),
		url('/fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Medium.eot');
	src: local('Montserrat Medium'), local('/fonts/Montserrat-Medium'),
		url('/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-Medium.woff') format('woff'),
		url('/fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-ExtraBold.eot');
	src: local('Montserrat ExtraBold'), local('/fonts/Montserrat-ExtraBold'),
		url('/fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-ExtraBold.woff') format('woff'),
		url('/fonts/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Black.eot');
	src: local('Montserrat Black'), local('/fonts/Montserrat-Black'),
		url('/fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-Black.woff') format('woff'),
		url('/fonts/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-ThinItalic.eot');
	src: local('Montserrat Thin Italic'), local('/fonts/Montserrat-ThinItalic'),
		url('/fonts/Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-ThinItalic.woff') format('woff'),
		url('/fonts/Montserrat-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'TT Commons';
	src: url('/fonts/TTCommons-Light.eot');
	src: local('TT Commons Light'), local('/fonts/TTCommons-Light'),
	url('/fonts/TTCommons-Light.eot?#iefix') format('embedded-opentype'),
	url('/fonts/TTCommons-Light.woff') format('woff'),
	url('/fonts/TTCommons-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'TT Commons';
	src: url('/fonts/TTCommons-Black.eot');
	src: local('TT Commons Black'), local('/fonts/TTCommons-Black'),
	url('/fonts/TTCommons-Black.eot?#iefix') format('embedded-opentype'),
	url('/fonts/TTCommons-Black.woff') format('woff'),
	url('/fonts/TTCommons-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'TT Commons';
	src: url('/fonts/TTCommons-Medium.eot');
	src: local('TT Commons Medium'), local('/fonts/TTCommons-Medium'),
	url('/fonts/TTCommons-Medium.eot?#iefix') format('embedded-opentype'),
	url('/fonts/TTCommons-Medium.woff') format('woff'),
	url('/fonts/TTCommons-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'TT Commons';
	src: url('/fonts/TTCommons-Regular.eot');
	src: local('TT Commons Regular'), local('/fonts/TTCommons-Regular'),
	url('/fonts/TTCommons-Regular.eot?#iefix') format('embedded-opentype'),
	url('/fonts/TTCommons-Regular.woff') format('woff'),
	url('/fonts/TTCommons-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TT Commons';
	src: url('/fonts/TTCommons-ExtraBold.eot');
	src: local('TT Commons ExtraBold'), local('/fonts/TTCommons-ExtraBold'),
	url('/fonts/TTCommons-ExtraBold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/TTCommons-ExtraBold.woff') format('woff'),
	url('/fonts/TTCommons-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'TT Commons';
	src: url('/fonts/TTCommons-Bold.eot');
	src: local('TT Commons Bold'), local('/fonts/TTCommons-Bold'),
	url('/fonts/TTCommons-Bold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/TTCommons-Bold.woff') format('woff'),
	url('/fonts/TTCommons-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'TT Commons';
	src: url('/fonts/TTCommons-ExtraLight.eot');
	src: local('TT Commons ExtraLight'), local('/fonts/TTCommons-ExtraLight'),
	url('/fonts/TTCommons-ExtraLight.eot?#iefix') format('embedded-opentype'),
	url('/fonts/TTCommons-ExtraLight.woff') format('woff'),
	url('/fonts/TTCommons-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'TT Commons';
	src: url('/fonts/TTCommons-DemiBold.eot');
	src: local('TT Commons DemiBold'), local('/fonts/TTCommons-DemiBold'),
	url('/fonts/TTCommons-DemiBold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/TTCommons-DemiBold.woff') format('woff'),
	url('/fonts/TTCommons-DemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'TT Commons';
	src: url('/fonts/TTCommons-Thin.eot');
	src: local('TT Commons Thin'), local('/fonts/TTCommons-Thin'),
	url('/fonts/TTCommons-Thin.eot?#iefix') format('embedded-opentype'),
	url('/fonts/TTCommons-Thin.woff') format('woff'),
	url('/fonts/TTCommons-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}


@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url('/fonts/HelveticaNeueCyr-Roman.otf');
	src: local('HelveticaNeueCyr Roman'), local('/fonts/HelveticaNeueCyr-Roman'),
	url('/fonts/HelveticaNeueCyr-Roman.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}



@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url('/fonts/HelveticaNeueCyr-Medium.otf');
	src: local('HelveticaNeueCyr Medium'), local('/fonts/HelveticaNeueCyr-Medium'),
	url('/fonts/HelveticaNeueCyr-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}


@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url('/fonts/HelveticaNeueCyr-MediumItalic.eot');
	src: local('HelveticaNeueCyr Medium Italic'), local('/fonts/HelveticaNeueCyr-MediumItalic'),
	url('/fonts/HelveticaNeueCyr-MediumItalic.otf') format('opentype');
	font-weight: 500;
	font-style: italic;
}