.vacancy__more {
    height: 443px;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    overflow: hidden;
    padding-top: 95px;
    margin-top: 26px
}

.vacancy__more-img {
    height: 348px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    background: #F5F7FB
}

.vacancy__more-img .photo {
    width: 364px;
    height: 438px;
    position: absolute;
    right: 13.5%;
    bottom: 0;
    transform: scaleX(-1)
}

.vacancy__more-img .desc {
    position: absolute;
    height: 348px;
    bottom: 0;
    right: -145px
}

.vacancy__more-img .mob {
    display: none
}

@media (max-width: 1140px) {
    .vacancy__more-img .desc {
        right: -210px
    }
}

@media (max-width: 990px) {
    .vacancy__more {
        margin-top: 0;
        height: 420px;
        padding-top: 70px
    }

    .vacancy__more-img {
        height: 300px
    }

    .vacancy__more-img .desc {
        right: -300px;
        height: 300px
    }

    .vacancy__more-img .photo {
        right: 2.5%
    }
}

@media (max-width: 760px) {
    .vacancy__more {
        margin-left: -20px;
        margin-right: -20px;
        height: auto;
        display: block;
        padding-top: 50px;
        overflow: initial;
        margin-top: 38px
    }

    .vacancy__more-img {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
        height: 199px;
        background: #EF6957
    }

    .vacancy__more-img .desc {
        display: none
    }

    .vacancy__more-img .mob {
        display: block;
        position: absolute;
        left: -1px;
        top: -1px;
        bottom: 0
    }

    .vacancy__more-img .photo {
        width: 225px;
        height: 271px;
        right: auto;
        left: 47px
    }
}


/* Vacancy text */

.vacancy__text {
    padding: 80px 156px;
    position: relative;
    z-index: 1
}

.vacancy__text .p2,.vacancy__text h2 {
    color: #ffffff
}

.vacancy__text .p2 {
    margin-top: 16px
}

.vacancy__text .btn2 {
    margin-top: 40px;
    height: 64px;
    font-size: 20px;
    line-height: 24px;
    border-color: transparent
}


@media (max-width: 1140px) {
    .vacancy__text {
        padding-left: 110px;
        padding-right: 110px
    }
}

@media (max-width: 990px) {
    .vacancy__text {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 120px
    }

    .vacancy__text .btn2 {
        margin-top: 30px;
        font-size: 14px;
        line-height: 16px;
        height: 48px;
        padding-top: 7px
    }

    .vacancy__text .p {
        margin-top: 8px
    }
}

@media (max-width: 760px) {
    .vacancy__text {
        padding: 20px 20px 36px;
        background: #EF6957
    }
}

@media (max-width: 575px) {
    .vacancy__text .p2 {
        font-size: 14px;
        line-height: 20px;
        margin-top: 8px
    }

    .vacancy__text .btn2 {
        margin-top: 24px;
        width: 100%;
        max-width: 400px
    }
}

/* Vacancy subscr */


.vacancy__subscr {
    margin-top: 81px;
    margin-bottom: 80px;
    position: relative
}

.vacancy__subscr-wp {
    position: relative;
    height: 350px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.vacancy__subscr-img {
    right: 0;
    width: 100%;
    height: 100%
}

.vacancy__subscr-img,.vacancy__subscr-img .photo {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0
}

.vacancy__subscr-img .desc {
    position: absolute;
    top: 0;
    left: 33%;
    bottom: 0;
    z-index: 1
}

.vacancy__subscr-text {
    position: relative;
    z-index: 2;
    padding: 80px;
    margin-right: 17.8%
}

.vacancy__subscr-text .p2,.vacancy__subscr-text h2 {
    color: #ffffff
}

.vacancy__subscr-text .p2 {
    margin-top: 16px
}

.vacancy__subscr-text .btn2 {
    margin-top: 40px;
    height: 64px;
    font-size: 20px;
    line-height: 24px;
    border-color: transparent
}

@media (max-width: 1440px) {
    .vacancy__subscr-img .desc {
        left: 31%
    }

    .vacancy__subscr-text .btn2 {
        height: 56px
    }
}

@media (max-width: 1140px) {
    .vacancy__subscr-wp {
        overflow: hidden
    }

    .vacancy__subscr-text {
        margin-right: 6%
    }
}

@media (max-width: 990px) {
    .vacancy__subscr {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .vacancy__subscr-img img,.vacancy__subscr-wp {
        height: 300px
    }

    .vacancy__subscr-text {
        margin-right: 0
    }

    .vacancy__subscr-text .p2 {
        margin-top: 8px
    }

    .vacancy__subscr-text .btn2 {
        height: 48px;
        margin-top: 24px
    }
}

@media (max-width: 760px) {
    .vacancy__subscr .container {
        padding: 0
    }

    .vacancy__subscr-wp {
        display: block;
        height: auto
    }

    .vacancy__subscr-img {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
        height: 199px;
        background: #4985DF
    }

    .vacancy__subscr-img .desc {
        display: none
    }

    .vacancy__subscr-img .mob {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: auto
    }

    .vacancy__subscr-img .photo {
        height: 100%;
        display: none
    }

    .vacancy__subscr-text {
        background: #4985DF;
        padding: 20px 20px 36px
    }
}

@media (max-width: 575px) {
    .vacancy__subscr {
        margin-bottom: 0
    }

    .vacancy__subscr-text .p2 {
        font-size: 14px;
        line-height: 20px;
        margin-top: 8px
    }

    .vacancy__subscr-text .btn2 {
        margin-top: 24px;
        width: 100%;
        max-width: 400px;
        font-size: 14px;
        line-height: 16px
    }
}

/* Vacancy banner */

.vacancy-list__banner {
    padding-top: 30px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    height: 400px;
    margin-top: 6px;
    margin-right: -20px;
    margin-bottom: 53px
}

.vacancy-list__banner-img {
    height: 350px;
    background-color: #F5F7FB;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0
}

.vacancy-list__banner-img .desc {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%
}

.vacancy-list__banner-img .photo {
    position: absolute;
    right: -40px;
    bottom: 0;
    width: 356px;
    height: 428px;
    transform: scaleX(-1)
}

.vacancy-list__banner-img .mob {
    display: none
}

.vacancy-list__banner-text {
    position: relative;
    z-index: 2;
    padding: 19px 5% 0 12.3%
}

.vacancy-list__banner-text .p2 {
    margin-top: 16px;
    margin-bottom: 40px
}

.vacancy-list__banner-text .btn {
    height: 64px;
    font-size: 20px;
    line-height: 24px;
    max-width: 301px;
    width: 100%
}

.vacancy-list__banner+.vacancy-list__item {
    border-top: 1px solid #E2E3E6
}


@media (max-width: 1440px) {
    .vacancy-list__banner {
        margin-right: 10px
    }

    .vacancy-list__banner-img .desc {
        right: -30px
    }

    .vacancy-list__banner-img .photo {
        right: -56px
    }

    .vacancy-list__banner-text {
        padding-left: 4.5%
    }

    .vacancy-list__banner .btn {
        height: 56px
    }
}

@media (max-width: 1140px) {
    .vacancy-list__banner-img .photo {
        right: -65px
    }

    .vacancy-list__banner-img .desc {
        right: -36px
    }
}

@media (max-width: 760px) {
    .vacancy-list__banner {
        margin:49px -20px 11px;
        display: block;
        height: auto
    }

    .vacancy-list__banner-text {
        width: 100%;
        background: #F5F7FB;
        padding: 20px 20px 36px
    }

    .vacancy-list__banner-text .btn {
        font-size: 14px;
        line-height: 16px;
        height: 48px
    }

    .vacancy-list__banner-text .p2 {
        margin-bottom: 24px;
        margin-top: 8px;
        font-size: 14px;
        line-height: 20px
    }

    .vacancy-list__banner-img {
        position: relative;
        height: 235px
    }

    .vacancy-list__banner-img .desc {
        display: none
    }

    .vacancy-list__banner-img .mob {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%
    }

    .vacancy-list__banner-img .photo {
        transform: scale(-1.2,1.2)
    }

    .vacancy-list__banner+.vacancy-list__item {
        border-top: none
    }

    .vacancy-list__banner-img {
        height: 199px
    }

    .vacancy-list__banner-img .photo {
        left: 47px;
        right: auto;
        width: 225px;
        height: 271px;
        transform: scaleX(-1)
    }

    .vacancy-list__banner-img .mob {
        height: auto
    }
}



